import { html } from "lit-html";
import { translate } from "@escapio/shared/l10n/translate";
import { FormWithPassword } from "./FormWithPassword";
import { stateful } from "@escapio/shared/components/element-builder";

customElements.define(
	"esc-customer-login",
	stateful(
		class extends FormWithPassword {
			get successTarget() {
				return this.getAttribute("success-target");
			}

			onSuccess() {
				window.location = this.successTarget;
			}

			renderFormElements() {
				return html`${[
					this.renderInput(translate("customer_login_label_email"), "email"),
					this.renderPasswordInput(
						translate("customer_login_label_password"),
						"password",
					),
					html`<p class="form-element--top-spacing font-size-small">
							${translate("customer_login_password_reset_info")}
							<a href=${this.getAttribute("password-reset-url")}
								>${translate("customer_login_password_reset_link_label")}</a
							>.
						</p>
						<p class="form-element--top-spacing font-size-small">
							${translate("customer_login_signup_info")}
							<a href=${this.getAttribute("signup-url")}
								>${translate("customer_login_signup_link_label")}</a
							>.
						</p>`,
					this.renderSubmitButton(translate("customer_login_label_submit")),
				]}`;
			}
		},
	),
);
